import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'

class BlogCategoryListTemplate extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages, basePath } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1
        ? `/${basePath}/`
        : `/${basePath}/${currentPage - 1}`
    const nextPage = `/${basePath}/${currentPage + 1}`

    return (
      <Layout>
        {posts.map(({ node }) => {
          return (
            <article className="post" key={node.fields.slug}>
              {node.frontmatter.img &&
                node.frontmatter.img.childImageSharp &&
                node.frontmatter.img.childImageSharp.fluid && (
                  <Link
                    to={node.fields.slug}
                    className="post-thumbnail"
                    style={{
                      backgroundImage: `url(${node.frontmatter.img.childImageSharp.fluid.src})`,
                    }}
                  />
                )}
              <div className="post-content">
                <h2 className="post-title">
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </h2>
                <p className="post-excerpt-multi-line">{node.excerpt}</p>
                <span className="post-date">
                  {node.frontmatter.updated}&nbsp;&nbsp;—&nbsp;
                </span>
                <span className="post-words">
                  {node.timeToRead} minute read
                </span>
              </div>
            </article>
          )
        })}

        <div className="pagination-container">
          <nav className="pagination" role="pagination">
            {!isFirst && (
              <Link to={prevPage} rel="prev" className="newer-posts">
                ← Previous Page |
              </Link>
            )}
            <span className="page-number">
              Page {currentPage} of {numPages}
            </span>
            {!isLast && (
              <Link to={nextPage} rel="next" className="older-posts">
                | Next Page →
              </Link>
            )}
          </nav>
        </div>
      </Layout>
    )
  }
}

export default BlogCategoryListTemplate

export const pageQuery = graphql`
  query blogCategoryPageQuery(
    $skip: Int!
    $limit: Int!
    $mainCategory: String
    $subCategory: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___created], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          category: { in: [$mainCategory] }
          subCategory: { in: [$subCategory] }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            created(formatString: "YYYY, MMM DD")
            updated(formatString: "YYYY, MMM DD")
          }
        }
      }
    }
  }
`
